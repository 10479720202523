import { Link } from "gatsby";
import React from "react";
import { css } from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

const pageStyles = css`
  margin: auto;
`;

const formStyles = css`
  max-width: 400px;

  .form-group {
    margin-bottom: 10px;
  }

  fieldset {
    margin-bottom: 20px;
  }

  input,
  textarea {
    margin-bottom: 10px;
    border: 1px solid #ddd;

    padding: 5px 0 5px 5px;

    transition: all 0.3s ease-in-out;
    width: 100%;
  }

  label {
    display: block;
  }

  .label_subtext {
    font-size: var(--typeScaleSmall);
  }

  textarea {
    height: 100px;
    overflow: auto;
  }
`;

const seoConfig = {
  title: "Submit A Business",
  description:
    "Own a restaurant? Your favourite spot missing from the list? Get in touch and we'll add it to the list.",
};

const Submission = () => {
  return (
    <Layout>
      <SEO {...seoConfig} />
      <section css={pageStyles}>
        <h1>Submit a Business</h1>

        <p>
          Most fields below are optional, provide any information you can and
          we'll do our best. If you need to contact us directly, try our{" "}
          <Link to="/feedback">feedback form</Link> or email at{" "}
          <a href="mailto:david@eatnearme.ca">david@eatnearme.ca</a>
        </p>

        <form
          css={formStyles}
          name="submission"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="submission" />
          <input type="hidden" name="bot-field" />

          <div className="form-group">
            <label htmlFor="restaurant-name">
              Name of Business (Required){" "}
            </label>
            <input
              id="restaurant-name"
              name="restaurant-name"
              type="text"
              placeholder="e.g. Joe's Eatery"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="restaurant-email">
              Email ( for us to contact you )
            </label>
            <input
              id="restaurant-email"
              name="restaurant-email"
              type="email"
              placeholder="example@domain.com"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="restaurant-address">Address</label>
            <input
              id="restaurant-address"
              name="restaurant-address"
              type="text"
              placeholder="123 James St N, Hamilton"
            />
          </div>

          <div className="form-group">
            <label htmlFor="restaurant-description">Description</label>
            <textarea
              name="restaurant-description"
              id="restaurant-description"
              placeholder="Describe your business. Keep it short, this is a quick pitch to tell people what you're about. Maximum 200 characters."
              maxLength="200"
            />
          </div>

          <div className="form-group">
            <label htmlFor="restaurant-description">Extra Information</label>
            <textarea
              name="restaurant-description-extra"
              id="restaurant-description-extra"
              placeholder="Advertise any specials, ordering requirements, payment restrictions, etc. Any information you think people should know before they try to order."
              maxLength=""
            />
          </div>

          <div className="form-group">
            <label htmlFor="restaurant-hours">Hours</label>
            <textarea
              name="restaurant-hours"
              id="restaurant-hours"
              placeholder="When are you open?"
            />
          </div>

          <fieldset className="inline">
            <legend>Ordering Details</legend>

            <div className="form-group">
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name="restaurant-tags[]"
                  value="Delivery"
                  id="delivery"
                />
                <label htmlFor="delivery">
                  Do you offer in-house delivery?
                </label>
              </div>
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name="restaurant-tags[]"
                  value="Takeout"
                  id="takeout"
                />
                <label htmlFor="takeout"> Do you offer pickup / takeout?</label>
              </div>
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name="restaurant-tags[]"
                  value="curbside"
                  id="curbside"
                />
                <label htmlFor="curbside">
                  Have you implemented a curbside pickup setup?
                </label>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="restaurant-phone">Phone</label>
              <input
                id="restaurant-phone"
                name="restaurant-phone"
                type="tel"
                placeholder="(905)-123-7890"
              />
            </div>
          </fieldset>

          <fieldset>
            <legend>Ordering Services</legend>
            <div className="form-group">
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name="delivery-services[]"
                  value="SkipTheDishes"
                  id="skip"
                />
                <label htmlFor="skip">Skip The Dishes</label>
              </div>

              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name="delivery-services[]"
                  value="DoorDash"
                  id="dash"
                />
                <label htmlFor="dash">Doordash</label>
              </div>

              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name="delivery-services[]"
                  value="UberEats"
                  id="uber"
                />
                <label htmlFor="uber">Uber Eats</label>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Additional Details</legend>
            <div className="form-group">
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name="restaurant-tags[]"
                  value="Alcohol"
                  id="alcohol"
                />
                <label htmlFor="alcohol">Do you serve alcohol?</label>
              </div>
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name="restaurant-tags[]"
                  value="Groceries"
                  id="groceries"
                />
                <label htmlFor="groceries">Are you a grocer?</label>
              </div>

              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name="restaurant-tags[]"
                  value="Vegetarian"
                  id="vegetarian"
                />
                <label htmlFor="vegetarian">
                  Do you offer vegetarian options?
                </label>
              </div>
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name="restaurant-tags[]"
                  value="Vegan"
                  id="vegan"
                />
                <label htmlFor="vegan">Do you offer vegan options?</label>
              </div>
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  name="restaurant-tags[]"
                  value="Halal"
                  id="halal"
                />
                <label htmlFor="halal">Do you offer halal options?</label>
              </div>
            </div>
          </fieldset>

          <div className="form-group">
            <label htmlFor="restaurant-moreTags">Descriptive Terms</label>
            <textarea
              name="restaurant-moreTags"
              id="restaurant-moreTags"
              maxLength="140"
              placeholder="Suggest some terms to categorize the business. e.g. Southern, Greek, Dessert, Pasta, Chicken."
            />
          </div>

          <div className="form-group">
            <label htmlFor="restaurant-extras">Extras</label>
            <textarea
              name="restaurant-extras"
              id="restaurant-extras"
              placeholder="Anything you'd like to add."
            />
          </div>

          <button type="submit">Submit</button>
        </form>
      </section>
    </Layout>
  );
};

export default Submission;
